import {Box, Slider} from "@mui/material";
import {useState} from "react";
import {useRouter} from "next/router";
import filterService from "../../../services/filterService";
import routeService from "../../../services/route/routeService";
import {useDispatch, useSelector} from "react-redux";
import {setQuery} from "../../../stores/filter/filterSlice";
import {State} from "../../../stores/store";
import {single} from "../../../utils/queryParams";

interface Props {
    filterKey: string;
    start: number;
    end: number;
    max?: number;
    shallow?: boolean;
    name?: string;
    label?: string;
    className?: string;
    displayFactor?: number;
    displayPrecision: number;
    step?: number;
    unit?: string;
}

export default function FilterOptionSlider({filterKey, start, end, step = 10, max, shallow = false, name, label, className = '', displayFactor = 1, displayPrecision, unit = ''}: Props) {
    const router = useRouter();
    const query = useSelector((state: State) => state.filterState.query);
    const filter = single(shallow ? query[filterKey] : router.query[filterKey]);
    const values: string[]|null = filter ? filter.split('_') : null;
    const startValue = Number(values ? values[0] : start);
    const endValue = Number(values ? values[1] : end);
    const [value, setValue] = useState<number[]>([Number(startValue) * displayFactor, Number(endValue) * displayFactor]);
    const dispatch = useDispatch();

    const handleChange = (event: Event, newValue: number | number[]) => {
        if (!Array.isArray(newValue)) {
            throw new Error('value should always be an array');
        }
        setValue(newValue);
    };

    async function routerPush() {
        let q = filterService.getQuery(shallow ? query : router.query);
        q.page = 1;
        q[filterKey] = `${value[0] / displayFactor}_${value[1] / displayFactor}`;

        if (shallow) return dispatch(setQuery(q));

        await router.push({
                pathname: routeService.getAsPath(router.asPath),
                query: q
            }, undefined,
            {shallow: shallow ?? false}
        )
    }

    return (
        <div className={`ps-2 pe-2 ${className}`}>
            <div>{name} ({value[0].toFixed(displayPrecision)}{unit} - {value[1].toFixed(displayPrecision)}{unit})</div>
            <Box>
                <Slider
                    aria-label={label}
                    value={value}
                    onChangeCommitted={routerPush}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    step={step}
                    min={0}
                    max={max ?? 300}
                />
            </Box>
        </div>
    )
}
